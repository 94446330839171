import React from 'react';
import founderImage from '../../images/founderImage.jpg'
import CarouselComponent from './CarouselComponent';

const AboutAndNotice = () => {
  const noticeArray = [
    {
      title: "Lorem Ipsum is simply dummy text of the printing and setting",
      date: "28",
      month: "Aug"
    },
    {
      title: "Lorem Ipsum is simply dummy text of the printing and setting",
      date: "28",
      month: "Aug"
    },
    {
      title: "Lorem Ipsum is simply dummy text of the printing and setting",
      date: "28",
      month: "Aug"
    },
    {
      title: "Lorem Ipsum is simply dummy text of the printing and setting",
      date: "28",
      month: "Aug"
    },
    {
      title: "Lorem Ipsum is simply dummy text of the printing and setting",
      date: "28",
      month: "Aug"
    },
  ]
  return (
    <div className=' mt-3 lg:pb-10 pb-1 '>
      <div className="lg:grid grid-cols-5 z-1 gap-3 lg:p-5 p-4">
        <div className=" bg-[#e7f4f6] lg:p-12 sm:p-5 p-3 rounded col-span-3">
          <p className='text-cyan text-lg font-[500] font-rubik'>ABOUT US</p>
          <p className='md:text-3xl text-2xl font-nunito font-bold leading-tight'>World Best Virtual Learning Network Educavo eLearning</p>
          <p className='text-lg font-rubik text-[#505050] leading-8'>Lorem ipsum dolor sit amet, consectetur adipisicing elit, eius to mod tempor incidi dunt ut labore et dolore magna aliqua. Ut enims ad minim veniam.Lorem ipsum dolor sit amet, consectetur adipisicing elit, eius to mod tempor incidi dunt ut labore et dolore magna aliqua. Ut enims ad minim veniam.Lorem sum dolor sit amet, consectetur adipisicing.</p>

          <div className="flex mt-5">
            <div className="h-[8rem] w-[8rem] ">
              <img src={founderImage} alt="" className='rounded-full' />
            </div>
            <div className="ml-7 ">
              <span className='pt-0.1'>
                <img src="https://keenitsolutions.com/products/html/educavo/assets/images/about/sign.png" alt="" />
              </span>
              <span className='p-1 text-2sm font-rubik font-medium'>
                CEO & Founder of Educavo
              </span>
            </div>


          </div>
        </div>
        <div className="my-1 flex justify-center items-start bg-[#F9F8F8] lg:p-5 p-3 col-span-2 pt-3 ">
          <div className="">
            <p className='text-3xl font-nunito font-bold text-center  text-cyan'>Get In Touck</p>
            <p className='text- font-rubik '>Lorem ipsum dolor sit amet, consectetur adipisicing elit, eius to mod tempor incidi dunt ut dolore.</p>
            <div className="grid grid-cols-2 gap-4 pt-4 ">
              <input type="text" placeholder='Name' className='p-2 appearance-none focus:outline-none focus:ring-0' />
              <input type="text" placeholder='Email' className='p-2 appearance-none focus:outline-none focus:ring-0' />
              <input type="text" placeholder='Phone' className='p-2 appearance-none focus:outline-none focus:ring-0' />
              <input type="text" placeholder='Subject' className='p-2 appearance-none focus:outline-none focus:ring-0' />
              <textarea name="message" id="" placeholder='Message' className='p-2 col-span-2 appearance-none focus:outline-none focus:ring-0' rows={6}></textarea>
              <button className='bg-cyan text-white p-2 rounded font-rubik font-medium text-md col-span-2'>Submit Now</button>
            </div>
          </div>

          {/* <CarouselComponent /> */}
        </div>

      </div>

    </div>
  );
}

export default AboutAndNotice;
