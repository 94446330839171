import React, { useContext, useState } from 'react';
import { Accordion, AccordionContext, Button, Card, Offcanvas, useAccordionButton } from 'react-bootstrap';
import { VscMenu } from "react-icons/vsc";
import logo from '../../images/logo.jpg'


const PINK = 'rgba(255, 192, 203, 0.6)';
const BLUE = 'rgba(0, 0, 255, 0.6)';

function ContextAwareToggle({ children, eventKey, callback }) {
    const { activeEventKey } = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(
        eventKey,
        () => callback && callback(eventKey),
    );

    const isCurrentEventKey = activeEventKey === eventKey;

    return (
        <span
            type="button"
            style={{}}
            onClick={decoratedOnClick}
        >
            {children}
        </span>
    );
}
const listItems = [
    { id: 1, name: 'Home' },
    { id: 2, name: 'About' },
    { id: 3, name: 'Courses' },
    { id: 4, name: 'Registration' },
    { id: 5, name: 'Boost' },
    { id: 6, name: 'Achievements' },
    { id: 7, name: 'Contact' }
];



const MobileMenu = () => {
    const [show, setShow] = useState(false);
    const [showChild, setShowChild] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <div >
            <div className="w-full flex justify-between items-center px-2">
                <div className="w-[25rem] flex justify-start items-center">
                    <span className='w-1/4'> <img src={logo} alt="logo image" /></span>
                    <span className='text-2xl font-nunito font-bold'>NEW LIFE
                        <span className='block text-sm font-nunito font-bold'>Rehabilitation & De-Addiction Centre</span>
                    </span>
                </div>

                <div className="">
                    <VscMenu color='' size={30} onClick={handleShow} className='cursor-pointer' />
                </div>

            </div>
            <Offcanvas show={show} onHide={handleClose} backdrop={true} scroll={true} style={{ width: '16rem' }} backdropClassName={show ? "custom-backdrop" : "custom-backdrop-close"} >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>
                        <img className='w-[5rem] mt-0' src={logo} alt="logo" />
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className='mt-0 pt-0'>
                    <ul className='w-full p-1 mt-0 pt-0'>
                        <li className="p-3 cursor-pointer hover:text-cyan hover:scale-y-110 font-rubik font-[420]">
                            HOME
                            {/* <span className="cursor-pointer" onClick={() => openDropdown('home')}></span>

                            <div
                                className={`pl-2 font-light transition-all duration-500 ease-in-out overflow-hidden ${showChild ? 'max-h-[1000px] opacity-100 scale-y-100' : 'max-h-0 opacity-0 scale-y-0'}`}
                                style={{ transitionProperty: 'max-height, opacity, transform' }}
                            >
                                <span className="block">raaz</span>
                                <span className="block">raaz</span>
                                <span className="block">raaz</span>
                            </div> */}
                        </li>
                        <li className='p-3 cursor-pointer hover:text-cyan hover:scale-y-110 font-rubik font-[420]'>ABOUT</li>
                        <li className='p-3 cursor-pointer hover:text-cyan hover:scale-y-110 font-rubik font-[420]'>SERVICES</li>
                        <li className='p-3 cursor-pointer hover:text-cyan hover:scale-y-110 font-rubik font-[420]'>ACHIVEMENTS</li>
                        <li className='p-3 cursor-pointer hover:text-cyan hover:scale-y-110 font-rubik font-[420]'>CONTACT</li>
                    </ul>
                </Offcanvas.Body>
            </Offcanvas>
        </div >
    );
}

export default MobileMenu;